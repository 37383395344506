"use strict";

import Select from './src/select';
import Modal from './src/modal';
import Swiper, { Navigation, Pagination, Autoplay, Lazy } from 'swiper';
//import Swiper from 'swiper/js/swiper';

// configure Swiper to use modules
Swiper.use([Navigation, Pagination, Autoplay, Lazy]);
let myHeroSwiper = new Swiper('[data-swiper="hero"]', {
    loop: true,
    speed: 1500,
    autoplay: {
        delay: 7500,
        disableOnInteraction: true,
    },
    pagination: {
        el: '[data-swiper-pagination]',
        clickable: true,
    },
    navigation: {
        prevEl: '[data-swiper-prev]',
        nextEl: '[data-swiper-next]',
    },
});

window._wq = window._wq || [];
const wistiaSlides = Select.all('[data-wistia-slide]');
if(!!wistiaSlides && wistiaSlides.length){
    wistiaSlides.forEach((wistiaSlide)=>{
        _wq.push({
            id: wistiaSlide.getAttribute('data-wistia-slide'),
            options: {
                popover: true,
                videoFoam: true,
            },
            onReady: function(video) {
                video.bind("popovershow", function() {
                    myHeroSwiper.autoplay.stop();
                });
                video.bind("popoverhide", function() {
                    myHeroSwiper.autoplay.start();
                });
                video.bind("end", function() {
                    setTimeout(()=>{video.popover.hide();}, 1000);
                });
                if(!!wistiaSlide){
                    wistiaSlide.addEventListener('click', function (e) {
                        video.play();
                    });
                }
            }
        });

    });
}


const myProductCarousels = Select.all('[data-swiper="product-carousel"]');
if( !!myProductCarousels && myProductCarousels.length ){
    myProductCarousels.forEach(function(productCarousel){
        new Swiper(productCarousel, {
            lazy: true,
            loop: true,
            speed: 1000,
            slidesPerView: 1,
            spaceBetween: 32,
            //grabCursor: true,
            breakpoints: {
                960: {
                    slidesPerView: 3,
                    spaceBetween: 32,
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 32,
                },
            },
            navigation: {
                prevEl: '[data-swiper-prev]',
                nextEl: '[data-swiper-next]',
            },
            on: {
                click: function (e) {
                    if(!!this.clickedSlide){
                        const slideElementDestination = this.clickedSlide.getAttribute('data-slide-destination'),
                            slideElementTarget = this.clickedSlide.getAttribute('data-slide-target');
                        if( slideElementDestination !== null && slideElementDestination ){
                            if( slideElementTarget !== null && slideElementTarget )
                                window.open(slideElementDestination);
                            else
                                window.location = slideElementDestination;
                        }
                    }
                },
            }
        });
    });
}

//mostad modal
const mostadTransitionContent = Select.one('[data-modal="mostad-tenenz-notification"]');
if( !!mostadTransitionContent ){
    Modal.closeOnBlur = false;
    Modal.btnAccept.callback = function(e){
        //e.preventDefault();
        if( !!url[candidateChosen] ){
            window.open(url[candidateChosen]);
        }
        //Modal.close(e);
        let d = document.getElementById("header__main__nav__list-item__link--mostad");
        d.classList.add("pulse-nav");
    };
    Modal.btnCancel.callback = function(e){
        //e.preventDefault();
        //Modal.close(e);
        let d = document.getElementById("header__main__nav__list-item__link--mostad");
        d.classList.add("pulse-nav");
    };
    Modal.btnClose.callback = function(e){
        //e.preventDefault();
        Modal.open();
        let d = document.getElementById("header__main__nav__list-item__link--mostad");
        d.classList.add("pulse-nav");
    };
    Modal.init();
    Modal.setCustomContent(mostadTransitionContent.innerHTML);
    Modal.open();
}
